import React, { Component } from 'react';
import logo from './logo.svg';
import './App.css';
import Routes from "./Routes";
import { IdentityContextProvider, useIdentityContext } from 'react-netlify-identity';
import { withRouter } from 'react-router-dom';


function App(props) {
  return(
    <IdentityContextProvider url={"https://usery.io"}>
      <AppBodyView />
    </IdentityContextProvider>
  )
}


export default withRouter(App);


function AppBodyView (){
  const { user, logoutUser, isLoggedIn } = useIdentityContext();
  const [dialog, setDialog] = React.useState(false);
  const [chatClient, setChatClient] = React.useState(false);

    return(
      <div>
        <Routes appProps={{ isLoggedIn, user, chatClient}} />
      </div>
    )
}
