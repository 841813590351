import React, { Component } from "react";
import "../../node_modules/react-vis/dist/style.css";
import "../../node_modules/placeholder-loading/src/scss/placeholder-loading.scss";
import {
  XYPlot,
  LineSeries,
  XAxis,
  YAxis,
  VerticalGridLines,
  HorizontalGridLines
} from "react-vis";
import PaperAlpacaAPI from "../libs/PaperAlpacaLib";
import "./EquityVisual.css";

export default class EquityVisual extends Component {
  constructor(props) {
    super(props);
    this.api = new PaperAlpacaAPI();
    this.state = {
      graphData: [],
      graphMin: 0,
      graphMax: 0
    };
  }

  roi(x) {
    let roi = (((x - 100000) / 100000) * 100).toFixed(2);
    return roi;
  }

  componentDidMount() {
    this.api.getEquityList().then(equityList => {
      let lst = [];
      let yMin = 10000000;
      let yMax = 0;

      for (let i = 0; i < equityList.length; i++) {
        let cY = equityList[i];
        lst.push({ x: i, y: cY });
        if (cY > yMax) {
          yMax = cY;
        }
        if (cY < yMin) {
          yMin = cY;
        }
      }
      this.setState({ graphData: [...lst], graphMin: yMin, graphMax: yMax });
    });
  }


  render() {
    const data = [
      { x: 0, y: 0 },
      { x: 1, y: 0 },
      { x: 2, y: 0 },
      { x: 3, y: 0 },
      { x: 4, y: 0 },
      { x: 5, y: 0 },
      { x: 6, y: 0 },
      { x: 7, y: 0 },
      { x: 8, y: 0 },
      { x: 9, y: 0 }
    ];
    // console.log(this.state.graphData);
    // console.log(data);
    // console.log(this.state.graphData.length);
    // console.log(Math.max(this.state.graphData));
    return (
      <div className="App">
        <div className="ai-equity-plot-card">
          {this.state.graphData.length === 0 ? 
          (
            <>
              <div className="roi-stats">
              <div className="ph-col-4 empty big"></div>
              </div>
              <h1 style={{color:'white'}}> hi</h1>
                <div className="ph-picture" style={{width:"380px", height: "300px"}}></div>
              <h4 className="description">
                Performance data for the ry Trading AI
              </h4>
            </>
          ) 
          : (
            <>
              <div className="roi-stats">
                <h1 className="ai-portfolio-value">
                  {this.roi(
                    this.state.graphData[this.state.graphData.length - 1].y
                  )}
                </h1>
                <h4>% ROI</h4>
              </div>
              <XYPlot
                height={300}
                width={380}
                yDomain={[this.state.graphMin - 100, this.state.graphMax + 100]}
              >
                <LineSeries data={this.state.graphData} color="#380BD1" />
                <YAxis />
              </XYPlot>
              <h4 className="description">
                Performance data for the ry Trading AI
              </h4>
            </>
          )}
        </div>
      </div>
    );
  }
}
