import React from "react";
import { Route, Redirect } from "react-router-dom";

export default function PrivateRoute({ component: C, appProps, ...rest }) {
  return (
    <Route
      {...rest}
      render={props =>
        appProps.isLoggedIn
          ? <C {...props} {...appProps} />
          : <Redirect
                to={"/"}
            //   to={`/login?redirect=${props.location.pathname}${props.location
            //     .search}`}
            />}
    />
  );
}