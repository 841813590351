import Alpaca from "@alpacahq/alpaca-trade-api";

const startDate = new Date(2018, 11, 24, 10, 33);

export default class AlpacaAPI {
  constructor( apiKeys ) {
    this.alpaca = new Alpaca({
      keyId: apiKeys.alpacaAPI,
      secretKey: apiKeys.alpacaSecret,
      paper: apiKeys.paper
    });
    this.portfolioValue = "0";
    this.equityList = [];
  }

  setValues() {
    this.getPortfolioHistory()
  }

  async getEquityList(){
      let portfolioHistory = await this.alpaca
      .getPortfolioHistory({
        date_start: startDate,
        date_end: new Date(),
        period: "all",
        timeframe: "1H",
        extended_hours: false,
      })
      let el = portfolioHistory.equity
      return el
  }

  async getaccount(){
    this.alpaca.getAccount().then(account => console.log(account))
  }

  async getGainLoss(){
    let acc = await this.alpaca.getAccount()
    let gl = ( ( acc.equity - acc.last_equity ) / acc.last_equity ) * 100
    return gl.toFixed(2)
    
  }

  async getPortfolioHistory() {
    this.alpaca
      .getPortfolioHistory({
        date_start: startDate,
        date_end: new Date(),
        period: "all",
        timeframe: "1H",
        extended_hours: false,
      })
      .then((ph) => {
          this.equityList = ph.equity
      });
  }

}
