import React, { Component } from "react";
import "./ConfirmEmail.css";



export default class ConfirmEmail extends Component {

render(){
    return (
        <div className="confirm-email-card">
            <h3>Thank you for signing up!</h3>
            <div>Please check your email to confirm your account</div>
        </div>
      );
}
  
}
