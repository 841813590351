import React, { useState } from "react";
import { Button, Form } from "react-bootstrap";
import Particles from "react-particles-js";
import {
  BrowserView,
  MobileView,
  isBrowser,
  isMobile
} from "react-device-detect";
import "./Home.css";

import "../../node_modules/react-vis/dist/style.css";

import LoaderButton from "../components/LoaderButton";
import EquityVisual from "../components/EquityVisual";
import {
  IdentityContextProvider,
  useIdentityContext
} from "react-netlify-identity";

const particleOpt = {
  particles: {
    number: {
      value: 100,
      getStarted: false
    }
  }
};

export default function Home(props) {
  const [getStarted, setGetStarted] = useState(false);
  const { loginUser, signupUser } = useIdentityContext();
  const [error, setError] = useState(null);
  const [errorText, setErrorText] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  function onGetStarted() {
    if (!getStarted) {
      setGetStarted(true);
    }
    // console.log("IS LOGGED IN?", props.isLoggedIn);
  }

  async function handleSubmit(event) {
    event.preventDefault();
    try {
      await loginUser(email, password, true);
      setError(null);
      setErrorText("");
      props.history.push("/dashboard");
    } catch (e) {
      alert(e);
      setError("error");
      setErrorText(
        "No account found with this email and password combination."
      );
    }
  }

  return (
    <div className="home">
      <BrowserView>
        <Particles className="particles" params={particleOpt} />
      </BrowserView>

      {isBrowser ? (
        <div className="wrapper">
          <div className="header-container">
            <h1 className="use-ry-header">use ry</h1>
            {props.isLoggedIn && props.user && getStarted == true ? (
              <Button
                className="header-login-button"
                onClick={() => {
                  props.history.push("/dashboard");
                }}
              >
                To Dashboard
              </Button>
            ) : (
              <Button
                className="header-login-button"
                onClick={() => {
                  onGetStarted();
                }}
              >
                Get Started
              </Button>
            )}
          </div>
          {getStarted ? (
            <div className="login-container">
              <div className="login-form-wrapper">
                <h1>Log in</h1>
                {/* <img src="../images/1x/Untitled-1.png" /> */}
                <br />
                <Form onSubmit={handleSubmit}>
                  <Form.Group controlId="formBasicEmail">
                    <Form.Label>Email address</Form.Label>
                    <Form.Control
                      type="email"
                      placeholder="Enter email"
                      value={email}
                      onChange={e => setEmail(e.target.value)}
                    />
                  </Form.Group>

                  <Form.Group controlId="formBasicPassword">
                    <Form.Label>Password</Form.Label>
                    <Form.Control
                      type="password"
                      placeholder="Password"
                      value={password}
                      onChange={e => setPassword(e.target.value)}
                    />
                  </Form.Group>

                  <LoaderButton variant="primary" type="submit">
                    Login
                  </LoaderButton>
                  <br />
                  {/* <Button
                    variant="light"
                    className="signup-button"
                    onClick={() => props.history.push("/signup")}
                  >
                    Click here to sign up
                  </Button> */}
                </Form>
              </div>
            </div>
          ) : (
            <div className="login-container">
              <EquityVisual />
            </div>
          )}
        </div>
      ) : (
        // mobile
        <div className="wrapper">
          {!getStarted ? (
            <div className="header-container">
              <h1 className="use-ry-header">use ry</h1>
              <Button
                className="header-login-button"
                onClick={() => {
                  onGetStarted();
                }}
              >
                Get Started
              </Button>
            </div>
          ) : (
            <div className="login-container">
              <div className="login-form-wrapper">
                <h1>Log in</h1>
                {/* <img src="../images/1x/Untitled-1.png" /> */}
                <br />
                <Form onSubmit={handleSubmit}>
                  <Form.Group controlId="formBasicEmail">
                    <Form.Label>Email address</Form.Label>
                    <Form.Control
                      type="email"
                      placeholder="Enter email"
                      value={email}
                      onChange={e => setEmail(e.target.value)}
                    />
                  </Form.Group>

                  <Form.Group controlId="formBasicPassword">
                    <Form.Label>Password</Form.Label>
                    <Form.Control
                      type="password"
                      placeholder="Password"
                      value={password}
                      onChange={e => setPassword(e.target.value)}
                    />
                  </Form.Group>

                  <LoaderButton variant="primary" type="submit">
                    Login
                  </LoaderButton>
                  <br />
                  {/* <Button
                    variant="light"
                    className="signup-button"
                    onClick={() => props.history.push("/signup")}
                  >
                    Click here to sign up
                  </Button> */}
                </Form>
              </div>
            </div>
          )}
        </div>
      )}
    </div>
  );
}
