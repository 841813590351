import React, { useState, useEffect } from "react";
import "./Dashboard.css";
import LoaderButton from "../components/LoaderButton";
import { Button, Form } from "react-bootstrap";
import Particles from "react-particles-js";
import Modal from "react-modal";
import { FaCheckCircle, FaTimesCircle } from "react-icons/fa";

import ky from "ky";
import {
  IdentityContextProvider,
  useIdentityContext
} from "react-netlify-identity";
import {
  BrowserView,
  MobileView,
  isBrowser,
  isMobile
} from "react-device-detect";
import DashboardVisuals from "../components/DashboardVisuals";

const particleOpt = {
  particles: {
    number: {
      value: 100,
      getStarted: false
    }
  }
};

const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    width: "400px",
    heigh: "400px",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    boxShadow: "0 5px 10px hsla(0, 0%, 0%, .2)"
  }
};

export default function Dashboard(props) {
  const [isLoading, setIsLoading] = useState(true);
  const { user, logoutUser, isLoggedIn, getFreshJWT } = useIdentityContext();
  const [dbAlpacaKeys, setDbAlpacaKeys] = useState({
    alpacaAPI: "",
    alpacaSecret: ""
  });
  const [isAPILoading, setIsAPILoading] = useState(false);
  const [isIsTradingLoading, setIsIsTradingLoading] = useState(false);
  const [hasKeys, setHasKeys] = useState(false);
  const [error, setError] = useState("");
  const [errorText, setErrorText] = useState("");
  const [alpacaAPIKey, setAlpacaAPIKey] = useState("");
  const [alpacaSecretKey, setAlpacaSecretKey] = useState("");
  const [isTrading, setIsTrading] = useState(false);
  const [show, setShow] = useState(false);

  useEffect(() => {
    getUserData().then(user => {
      checkDBHasKeys(user);
    });
  }, []); //listen for these variables to change

  async function getUserData() {
    const jwt = await getFreshJWT();
    const response = await ky
      .post("/.netlify/functions/getUserData", {
        json: {
          netlifyId: user.id
        },
        headers: {
          Authorization: "Bearer " + jwt
        }
      })
      .json();
    setDbAlpacaKeys({
      alpacaAPI: response.user.alpacaAPI,
      alpacaSecret: response.user.alpacaSecret
    });
    setIsTrading(response.user.isTrading);
    return response.user;
  }

  async function checkDBHasKeys(user) {
    if (user.alpacaAPI === "" || user.alpacaSecret === "") {
      setHasKeys(false);
      setIsLoading(false);
    } else {
      setAlpacaAPIKey(user.alpacaAPI)
      setAlpacaSecretKey(user.alpacaSecret)
      setHasKeys(true);
      setIsLoading(false);
    }
  }

  async function saveUserDBKeys() {
    const jwt = await getFreshJWT();
    const response = await ky
      .post("/.netlify/functions/updateUserData", {
        json: {
          userId: user.id,
          alpacaAPI: alpacaAPIKey,
          alpacaSecret: alpacaSecretKey
        },
        headers: {
          Authorization: "Bearer " + jwt
        }
      })
      .json();
  }

  async function saveUserIsTrading(isUsing) {
    const jwt = await getFreshJWT();
    const response = await ky
      .post("/.netlify/functions/updateUserData", {
        json: {
          userId: user.id,
          isTrading: isUsing
        },
        headers: {
          Authorization: "Bearer " + jwt
        }
      })
      .json();
  }

  async function handleTradeStatusChange() {
    if (isTrading) {
      setIsIsTradingLoading(true);
      setIsTrading(false);
      let b = await saveUserIsTrading(false);
      setIsIsTradingLoading(false);
      setShow(false);
      //submit change to the mongodb and python server
    } else {
      setIsIsTradingLoading(true);
      setIsTrading(true);
      let b = await saveUserIsTrading(true);
      setIsIsTradingLoading(false);
      //submit change to the mongodb and python server
    }
  }

  async function checkValidAlpaca() {
    const jwt = await getFreshJWT();
    const response = await ky
      .post("/.netlify/functions/testValidAlpaca", {
        json: {
          alpacaAPI: alpacaAPIKey,
          alpacaSecret: alpacaSecretKey
        },
        headers: {
          Authorization: "Bearer " + jwt
        }
      })
      .json();
    return response;
  }

  async function handleKeysSubmit(event) {
    event.preventDefault();
    setIsAPILoading(true);
    try {
      let response = await checkValidAlpaca();
      setHasKeys(true);
      saveUserDBKeys();
      setIsAPILoading(false);
    } catch (e) {
      setHasKeys(false);
      setErrorText("Error: Invalid API Keys");
      setIsAPILoading(false);
    }
  }

  return (
    <div className="dashboard">
      <BrowserView>
        <Particles className="particles" params={particleOpt} />
      </BrowserView>
      <div className="wrapper">
        <div className="dashboard-container">
          {isLoading ? (
            <div className="dashboard-form-wrapper">
              <h3>Loading...</h3>
            </div>
          ) : (
            <>
              {hasKeys ? (
                <div className="dashboard-form-wrapper">
                  <>
                    <Modal
                      isOpen={show}
                      onRequestClose={() => setShow(false)}
                      style={customStyles}
                      contentLabel="Example Modal"
                    >
                      <h3>
                        Are you sure you want to stop ry from trading for you?
                      </h3>
                      <hr />
                      <div>
                        You can always turn it back on in the dashboard.
                      </div>
                      <br />
                      <br />
                      <LoaderButton
                        className="really-stop-ry-button"
                        variant="primary"
                        onClick={() => {
                          handleTradeStatusChange(true);
                        }}
                      >
                        Stop ry
                      </LoaderButton>
                      <Button
                        variant="light"
                        className="close-button"
                        onClick={() => setShow(false)}
                      >
                        cancel
                      </Button>
                    </Modal>
                  </>
                  <div className="dashboard-header">
                    <h1>Dashboard</h1>
                    <Button
                      variant="light"
                      onClick={() => {
                        logoutUser();
                      }}
                    >
                      Log out
                    </Button>
                  </div>
                  <div className="activity-header">
                    {isTrading ? (
                      <>
                        <FaCheckCircle style={{color:"#28a745"}} />
                        <h4 className="ry-activity" style={{color:"#28a745"}} >Ry is enabled</h4>
                      </>
                    ) : (
                      <>
                        <FaTimesCircle style={{color:"#dc3545"}}  />
                        <h4 className="ry-activity" style={{color:"#dc3545"}} >Ry is disabled</h4>
                      </>
                    )}
                  </div>
                  {/* {alpacaAPIKey.length !== 0 ? */}
                  <DashboardVisuals apiKeys={ {alpacaAPI:alpacaAPIKey, alpacaSecret: alpacaSecretKey, paper: false}} />

                  {/* :
                  null
                  } */}
                  {isTrading ? (
                    <LoaderButton
                      className="stop-ry-button"
                      variant="primary"
                      isLoading={isIsTradingLoading}
                      onClick={() => {
                        setShow(true);
                      }}
                    >
                      Stop ry
                    </LoaderButton>
                  ) : (
                    <LoaderButton
                      className="use-ry-button"
                      variant="primary"
                      isLoading={isIsTradingLoading}
                      onClick={() => {
                        handleTradeStatusChange();
                      }}
                    >
                      Use ry
                    </LoaderButton>
                  )}
                </div>
              ) : (
                <div className="dashboard-form-wrapper">
                  <h1>Alpaca Trading Keys</h1>
                  <Button
                      variant="light"
                      onClick={() => {
                        logoutUser();
                      }}
                    >
                      Log out
                    </Button>
                  <br />
                  <p>
                    The Ry AI uses the Alpaca trading api to process trade
                    orders for free. In order to start trading, sign up on the
                    Alpaca website below.
                  </p>
                  <a href="https://alpaca.markets/" target="_blank">
                    https://alpaca.markets/
                  </a>
                  <br />
                  <p>
                    Once you've gotten your Alpaca API Keys, paste them below.
                    Don't forget to save your secret key somewhere safe.
                  </p>
                  {/* <img src="../images/1x/Untitled-1.png" /> */}
                  <br />
                  <Form onSubmit={handleKeysSubmit}>
                    <Form.Group controlId="formBasicAlpacaKey">
                      <Form.Label>Alpaca API Key</Form.Label>
                      <Form.Control
                        type="text"
                        placeholder="Enter Alpaca API Key"
                        value={alpacaAPIKey}
                        onChange={e => setAlpacaAPIKey(e.target.value)}
                      />
                    </Form.Group>

                    <Form.Group controlId="formBasicAlpacaSecret">
                      <Form.Label>Alpaca Secret Key</Form.Label>
                      <Form.Control
                        type="text"
                        placeholder="Enter Alpaca Secret Key"
                        value={alpacaSecretKey}
                        onChange={e => setAlpacaSecretKey(e.target.value)}
                      />
                      <Form.Control.Feedback>{error}</Form.Control.Feedback>
                    </Form.Group>
                    <br />
                    <h4 style={{ color: "red", textAlign: "center" }}>
                      {errorText}
                    </h4>
                    <LoaderButton
                      variant="primary"
                      type="submit"
                      isLoading={isAPILoading}
                    >
                      Save keys
                    </LoaderButton>
                  </Form>
                </div>
              )}
            </>
          )}
        </div>
      </div>
      <br />
    </div>
  );
}
