import React from "react";
import { Route, Switch } from "react-router-dom";
import Home from "./containers/Home";
import Signup from "./containers/Signup"
import ConfirmEmail from "./containers/ConfirmEmail";
import PublicRoute from "./components/PublicRoute";
import PrivateRoute from "./components/PrivateRoute";
import Dashboard from "./containers/Dashboard";

export default function Routes({ appProps}) {
  return (
    <Switch>
      <PublicRoute
        path="/"
        exact
        component={Home}
        appProps={appProps} 
      />

    {/* <PublicRoute
        path="/signup"
        exact
        component={Signup}
        appProps={appProps} 
      /> */}

      <PrivateRoute
        path="/dashboard"
        exact
        component={Dashboard}
        appProps={appProps} 
      />
      {/* <Route path="/" exact render={(props) => <Home {...props} {...appProps}  />}  />
      <Route path="/signup" exact render={(props) => <Signup {...props} {...appProps} />}  /> */}
      {/* <Route path="/signup/confirm" exact render={(props) => <ConfirmEmail {...props} {...appProps} />}  /> */}
    </Switch>
  );
}