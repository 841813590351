import Alpaca from "@alpacahq/alpaca-trade-api";

const startDate = new Date(2018, 11, 24, 10, 33);

export default class PaperAlpacaAPI {
  constructor() {
    this.alpaca = new Alpaca({
      keyId: "PK4NQ46D6JHIH6ZDNKNU",
      secretKey: "oFpvGp3co94cW5wRCUqEVofsWXVgbu7ssFXeehjZ",
      paper: true
    });
    this.portfolioValue = "0";
    this.equityList = [];

  }

  setValues() {
    this.getPortfolioHistory()
  }

  async getEquityList() {
    let portfolioHistory = await this.alpaca
      .getPortfolioHistory({
        date_start: startDate,
        date_end: new Date(),
        period: "all",
        timeframe: "1H",
        extended_hours: false,
      })
    let el = portfolioHistory.equity
    return el
  }

  async getPortfolioHistory() {
    this.alpaca
      .getPortfolioHistory({
        date_start: startDate,
        date_end: new Date(),
        period: "all",
        timeframe: "1H",
        extended_hours: false,
      })
      .then((ph) => {
        // console.log('Portfolio hIstory', ph)
        this.equityList = ph.equity
      });
  }

}
